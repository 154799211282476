main{
    #fee{
        padding:60px 0 50px;
        background:linear-gradient(-45deg,#ecf6ff 25%,#f5faff 25%,#f5faff 50%,#ecf6ff 50%,#ecf6ff 75%,#f5faff 75%) center center / 10px 10px;
        h2{
            img{
                max-width:60%;
            }
            .people{
                bottom:-20px;
                left:80%;
                max-width:20%;
                @include media-breakpoint-up(lg){
                    left:75%;
                    width:inherit;
                }
            }
        }
        .description{
            padding:25px;
            margin-bottom:25px;
            background:#fff;
            border:4px solid #ddd;
            border-radius:10px;
            ul{
                li.nocost{
                    width:100%;
                    padding:20px 40px;
                    margin-bottom:20px;
                    background:linear-gradient(to bottom,#ffa21e 0,#ff781e 100%);
                    border-radius:10px;
                    @include media-breakpoint-up(md){
                        width:inherit;
                        margin-right:25px;
                        margin-bottom:0;
                    }
                }
                li{
                    p{
                        font-size:14px;
                    }
                }
            }
        }
        .pack_list{
            li{
                display:flex;
                flex-direction:column;
                margin-bottom:15px;
                border:4px solid;
                border-radius:10px;
                @include media-breakpoint-up(md){
                    width:49.1%;
                    margin-bottom:25px;
                }
                .img{
                    padding:10px;
                    background:#fff;
                    border-radius:10px 10px 0 0;
                    text-align:center;
                }
                p{
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    flex-grow:1;
                    color:#fff;
                    font-weight:bold;
                    line-height:1.4;
                    padding:10px 20px;
                    @include media-breakpoint-up(lg){
                        padding:10px;
                    }
                }
                &:first-child{
                    border-color:map-get($color,'maincolor');
                    p{
                        background:linear-gradient(-45deg,map-get($color,'light_blue') 25%,map-get($color,'blue') 25%,map-get($color,'blue') 50%,map-get($color,'light_blue') 50%,map-get($color,'light_blue') 75%,map-get($color,'blue') 75%) center center / 10px 10px;
                        @include media-breakpoint-up(lg){
                            font-size:20px;
                        }
                    }
                }
                &:nth-child(2){
                    border-color:map-get($color,'orange');
                    p{
                        background:linear-gradient(-45deg,map-get($color,'orange') 25%,#f5a42f 25%,#f5a42f 50%,map-get($color,'orange') 50%,map-get($color,'orange') 75%,#f5a42f 75%) center center / 10px 10px;
                        text-align:left;
                        justify-content:flex-start;
                        padding:6px 20px 5px;
                    }
                }
                &:nth-child(3){
                    border-color:map-get($color,'sky');
                    p{
                        background:linear-gradient(-45deg,map-get($color,'sky') 25%,#59c2bb 25%,#59c2bb 50%,map-get($color,'sky') 50%,map-get($color,'sky') 75%,#59c2bb 75%) center center / 10px 10px;
                        @include media-breakpoint-up(lg){
                            font-size:20px;
                            padding:13px 0;
                        }
                    }
                }
                &:nth-child(4){
                    border-color:map-get($color,'pink');
                    p{
                        background:linear-gradient(-45deg,map-get($color,'pink') 25%,#ff7372 25%,#ff7372 50%,map-get($color,'pink') 50%,map-get($color,'pink') 75%,#ff7372  75%) center center / 10px 10px;
                    }
                }
            }
        }
        .attention{
            font-size:14px;
        }
    }
}



