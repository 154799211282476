
main{
    #estimate{
        padding:40px 0;
        .inner{
            padding:20px;
            border:4px solid map-get($color,'maincolor');
            text-align:center;
            @include media-breakpoint-up(lg){
                padding:20px 0;
            }
            p{
                font-size:3.5vw;
                font-weight:bold;
                @include media-breakpoint-up(md){
                    font-size:20px;
                }
            }
            h3{
                margin-top:15px;
                margin-bottom:20px;
                font-size:4.5vw;
                @include media-breakpoint-up(md){
                    margin-top:5px;
                    font-size:1.75rem;
                }
            }
            .list{
                @include media-breakpoint-up(lg){
                    width:85%;
                    margin:0 auto;
                }
                div{
                    &.pack{
                        @include media-breakpoint-up(md){
                            width:59.1%;
                        }
                        li{
                            width:50%;
                        }
                    }
                    &.option{
                        @include media-breakpoint-up(md){
                            width:39.1%;
                        }
                        h4{
                            background:#b6ad85;
                        }
                        li{
                            width:100%;
                        }
                    }
                    h4{
                        padding:13px 0;
                        font-size:4.5vw;
                        @include media-breakpoint-up(md){
                            font-size:20px;
                        }
                    }
                    ul{
                        padding:20px;
                        border:1px solid #ddd;
                        border-top:none;
                        @include media-breakpoint-up(lg){
                            padding:20px 40px;
                        }
                        li{
                            font-size:3.5vw;
                            font-weight:bold;
                            text-align:left;
                            @include media-breakpoint-up(md){
                                font-size:18px;
                            }
                            i{
                                margin-right:10px;
                            }
                        }
                    }
                }
            }
        }
    }
}



