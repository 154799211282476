main{
    #trouble{
        h2{
            margin-bottom:0;
            padding:17px 0;
        }
        .process{
            padding-top:40px;
            background:#f4f4f4;
            .inner{
                width:95%;
                margin:0 auto;
                @include media-breakpoint-up(lg){
                    width:70%;
                }
                .text{
                    @include media-breakpoint-up(md){
                        width:57.2%;
                    }
                    .border_box{
                        padding:20px 30px 0;
                        background:#fff;
                        border:4px solid #ddd;
                        border-radius:20px;
                        ul{
                            margin:10px 0 5px;
                            li{
                                padding:3px 0;
                                border-top:1px dashed #aaa;
                                font-weight:bold;
                                i{
                                    margin-right:10px;
                                }
                            }
                        }
                    }
                    p{
                        margin-top:15px;
                        font-size:3.5vw;
                        font-weight:bold;
                        line-height:1.4;
                        @include media-breakpoint-up(md){
                            font-size:18px;
                        }
                    }
                }
                .img{
                    display:none;
                    width:37.8%;
                    @include media-breakpoint-up(md){
                        display:block;
                    }
                }
            }
        }
        .works{
            position:relative;
            padding-top:35px;
            margin-bottom:75px;
            .border_box{
                padding:20px 0;
                border:4px solid map-get($color,'maincolor');
                border-radius:20px;
                &:after{
                    content:"";
                    position:absolute;
                    bottom:-58px;
                    left:50%;
                    display:inline-block;
                    width:133px;
                    height:72px;
                    background:url('../img/arrow_down_b.png') no-repeat center center;
                    transform:translateX(-50%);
                }
                .inner{
                    width:90%;
                    margin:0 auto;
                    @include media-breakpoint-up(md){
                        width:70%;
                    }
                    h3{
                        display:flex;
                        .sub{
                            display:none;
                            @include media-breakpoint-up(lg){
                                display:block;
                            }
                        }
                    }
                    ul{
                        justify-content:center;
                        margin-top:20px;
                        @include media-breakpoint-up(lg){
                            justify-content:space-between;
                        }
                        li{
                            width:33%;
                            padding:6px 0;
                            margin-bottom:10px;
                            border-radius:100px;
                            text-align:center;
                            font-size:2.5vw;
                            @include media-breakpoint-up(md){
                                width:32%;
                                margin:0 .5% 10px;
                                font-size:inherit;
                            }
                            @include media-breakpoint-up(lg){
                                width:24%;
                                margin:0 0 10px;
                            }
                        }
                    }
                }
            }
        }
        .request{
            li{
                margin:5px 0;
                @include media-breakpoint-up(md){
                    max-width:49.5%;
                    margin:0;
                }
            }
            p{
                margin:30px 0;
            }
        }
    }
}



