
main{
    #contact{
        padding:60px 0 70px;
        background:linear-gradient(-45deg,#ecf6ff 25%,#f5faff 25%,#f5faff 50%,#ecf6ff 50%,#ecf6ff 75%,#f5faff 75%) center center / 10px 10px;
        h2{
            margin-bottom:0;
        }
        form{
            background:#fff;
            padding:20px 0 50px;
            .inner{
                width:95%;
                max-width:1000px;
                margin:0 auto;
                .require{
                    padding:0 10px;
                    margin-left:5px;
                    border-radius:3px;
                    font-size:14px;
                    font-weight:normal;
                    @include media-breakpoint-up(lg){
                        margin-left:0;
                    }
                }
                li{
                    &:not(:last-child) dl{
                        border-bottom:1px solid #ddd;
                    }
                    dl{
                        align-items:center;
                        justify-content:space-between;
                        padding:23px 0;
                        @include media-breakpoint-up(lg){
                            display:flex;
                        }
                        dt{
                            display:flex;
                            align-items:center;
                            font-size:18px;
                            margin-bottom:10px;
                            @include media-breakpoint-up(lg){
                                justify-content:space-between;
                                width:25%;
                                margin-bottom:0;
                            }
                        }
                        dd{
                            font-size:4vw;
                            @include media-breakpoint-up(md){
                                font-size:inherit;
                            }
                            @include media-breakpoint-up(lg){
                                width:72%;
                            }
                            input:not([type='radio']){
                                appearance:none;
                                padding:8px;
                                border:none;
                                border-radius:5px;
                                background:#f4f4f4;
                                font-size:16px;
                                @include media-breakpoint-up(lg){
                                    width:60%;
                                }
                                &.w-80{
                                    @include media-breakpoint-up(lg){
                                        width:80%;
                                    }
                                }
                            }
                            select{
                                appearance:none;
                                padding:8px 12px;
                                border:none;
                                border-radius:5px;
                                background:#f4f4f4;
                                font-size:16px;
                            }
                            textarea{
                                padding:8px;
                                border:none;
                                border-radius:5px;
                                background:#f4f4f4;
                                font-size:16px;
                            }
                            label{
                                margin:0 20px 0 0;
                                input{
                                    margin-right:5px;
                                }
                            }
                            span{
                                margin:0 3px;
                                font-weight:bold;
                                @include media-breakpoint-up(md){
                                    margin:0 5px;
                                }
                                @include media-breakpoint-up(lg){
                                    margin:0 10px;
                                }
                            }
                        }
                    }
                }
                #policy{
                    max-height:220px;
                    margin:0 auto;
                    padding:20px;
                    border:1px solid #ddd;
                    overflow-y:auto;
                    font-size:2.5vw;
                    font-weight:normal;
                    @include media-breakpoint-up(md){
                        font-size:14px;
                        padding:20px 40px;
                    }
                    ol{
                        margin:20px 0;
                        li{
                            margin-bottom:20px;
                        }
                    }
                    h4,h5{
                        font-size:14px;
                        font-weight:normal;
                    }
                }
                #privacy_check{
                    font-size:4vw;
                    @include media-breakpoint-up(md){
                        font-size:inherit;
                    }
                    input{
                        display:none;
                    }
                    input+span{
                        position:relative;
                        display:inline-block;
                        vertical-align:middle;
                        width:20px;
                        height:20px;
                        margin-right:5px;
                        border:1px solid #aaa;
                        border-radius:3px;
                    }
                    input.unchecked+span{
                        background:#f1d0d5;
                    }
                    input:checked+span:after{
                        content:"";
                        position:absolute;
                        left:6px;
                        display:inline-block;
                        width:6px;
                        height:15px;
                        border-bottom:1px solid map-get($color,'maincolor');
                        border-right:1px solid map-get($color,'maincolor');
                        transform:rotate(45deg);
                    }
                }
                button{
                    display:block;
                    position:relative;
                    width:90%;
                    max-width:360px;
                    margin:0 auto;
                    padding:14px 0;
                    border:none;
                    border-radius:100px;
                    color:#fff;
                    font-size:18px;
                    text-align:center;
                    i{
                        position:absolute;
                        top:50%;
                        right:20px;
                        transform:translateY(-50%);
                    }
                }
            }
            #error{
                display:none;
                margin-top:20px;
                color:#f00;
                font-weight:bold;
                text-align:center;
            }
            #result{
                display:none;
                margin-top:20px;
                padding:15px 0;
                border:4px solid map-get($color,'maincolor');
                font-weight:bold;
                text-align:center;
            }
        }
    }
}



