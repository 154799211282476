
main{
    #voice{
        padding:30px 0;
        @include media-breakpoint-up(md){
            padding:75px 0 80px;
        }
        h2{
            margin-bottom:55px;
        }
        li.list{
            &:not(:last-child){
                margin-bottom:20px;
                @include media-breakpoint-up(md){
                    margin-bottom:50px;
                }
            }
            .left{
                @include media-breakpoint-up(md){
                    width:50%;
                }
                h3{
                    padding:12px 0;
                    font-size:5.5vw;
                    text-align:center;
                    @include media-breakpoint-up(md){
                        padding:22px 0;
                        font-size:30px;
                    }
                }
                .left_inner{
                    padding:35px 35px 0;
                    border-left:1px solid #ddd;
                    border-bottom:1px solid #ddd;
                    .profile{
                        display:flex;
                        justify-content:space-between;
                        margin-bottom:30px;
                        padding-bottom:30px;
                        border-bottom:1px dashed #ddd;
                        .chara{
                            width:56%;
                            border:1px solid #ddd;
                            @include media-breakpoint-up(lg){
                                width:37.8%;
                            }
                            .img{
                                padding:20px 0;
                                border-bottom:1px solid #ddd;
                                text-align:center;
                            }
                            p{
                                font-size:20px;
                                font-weight:bold;
                                text-align:center;
                            }
                        }
                        .cont{
                            width:40%;
                            @include media-breakpoint-up(lg){
                                width:56.8%;
                            }
                            li{
                                &:nth-child(2n){
                                    background:#fafafa;
                                }
                                dl{
                                    padding:5px 0;
                                    @include media-breakpoint-up(lg){
                                        display:flex;
                                        align-items:center;
                                        padding:15px;
                                    }
                                    dt{
                                        width:90px;
                                        padding:2.5px 0;
                                        margin-bottom:5px;
                                        border-radius:3px;
                                        font-size:14px;
                                        font-weight:normal;
                                        text-align:center;
                                        @include media-breakpoint-up(lg){
                                            margin-bottom:0;
                                            margin-right:10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .enquete{
                display:flex;
                justify-content:center;
                align-items:center;
                padding:10px 0;
                background:#f4f4f4;
                border:1px solid #ddd;
                @include media-breakpoint-up(md){
                    padding:0;
                }
                @include media-breakpoint-up(md){
                    width:50%;
                }
                img{
                    max-width:85%;
                    @include media-breakpoint-up(md){
                        max-width:95%;
                    }
                }
            }
        }
    }
}



