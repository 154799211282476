
main{
    #covit{
        padding:30px 0 40px;
        background:linear-gradient(-45deg,#f2ebc9 25%,#f8f3dd 25%,#f8f3dd 50%,#f2ebc9 50%,#f2ebc9 75%,#f8f3dd 75%) center center / 10px 10px;
        .container>p{
            margin-top:15px;
            font-size:14px;
            font-weight:bold;
            line-height:1.4;
        }
        .img{
            margin-bottom:10px;
            text-align:center;
            @include media-breakpoint-up(lg){
                width:40%;
                margin-bottom:0;
            }
        }
        .text{
            padding:25px 0;
            @include media-breakpoint-up(lg){
                width:58.5%;
            }
            h2{
                margin-bottom:20px;
            }
            .text_inner{
                width:90%;
                margin:0 auto;
                p{
                    font-size:3vw;
                    @include media-breakpoint-up(md){
                        font-size:16px;
                    }
                }
                ul{
                    margin-top:15px;
                    border-top:1px dashed #aaa;
                    li{
                        padding:3px 0;
                        border-bottom:1px dashed #aaa;
                        font-size:3.5vw;
                        @include media-breakpoint-up(md){
                            font-size:18px;
                        }
                        i{
                            margin-right:15px;
                            font-size:24px;
                        }
                    }
                }
            }
        }
    }
}



