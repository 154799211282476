/*========================================================
                        共通スタイル
=========================================================*/

body {
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
    font-size: 3vw;
    line-height:1.8;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: #333;
    padding-bottom:64px;
    @include media-breakpoint-up(md){
        padding-top:175px;
        padding-bottom:0;
        font-size: 16px;
    }
}

ul{
    margin-bottom:0;
}

h1,
h2,
h3,
h4 {
    margin: 0;
    font-weight: bold;
}

p,ul,dl,dt,dd,figure{
    margin:0;
}

img{
    max-width:100%;
}

.logo{
    max-width:214px;
}

a {
    color: inherit;
    text-decoration: none;
    &:link,
    &:active,
    &:visited,
    &:focus {
        color: inherit;
    }
    &:hover {
        text-decoration: none;
        img {
            filter: alpha(opacity=80);
            -moz-opacity: 0.8;
            opacity: 0.8;
        }
    }
}

br.sp{
    @include media-breakpoint-up(md){
        display:none;
    }
}

br.tab{
    @include media-breakpoint-up(lg){
        display:none;
    }
}

label{
    cursor:pointer;
}

div:focus,button:focus,a:focus,input:focus,textarea:focus{
    outline:0;
}

.container {
    width: 100%;
    max-width: 1130px;
    @include media-breakpoint-up(md){
        padding-right:30px;
        padding-left:30px;
    }
    &.w_long {
        max-width: 1830px;
    }
    &.w-1000{
        max-width:1060px;
    }
}
.w-1000{
    max-width:1060px;
    width:100%;
    padding:0 30px;
}

.en{
    font-family:'Arial',serif;
}

.normal_design{
    position:relative;
    padding:20px 0;
    margin-bottom:40px;
    background:linear-gradient(to bottom,map-get($color,'light_blue') 50%,map-get($color,'blue') 50%);
    line-height:1;
    text-align:center;
    @include media-breakpoint-up(md){
        padding:33px 0;
    }
    &:after{
        content:"";
        position:absolute;
        left:calc(50% - 20px);
        bottom:-40px;
        display:inline-block;
        border:20px solid transparent;
        border-top-color:map-get($color,'blue');
    }
    img{
        max-width:90%;
    }
}

.ex_design{
    position:relative;
    margin-bottom:20px;
    text-align:center;
    .people{
        display:none;
        position:absolute;
        @include media-breakpoint-up(md){
            display:block;
        }
    }
}

.bg_gradation{
    background:linear-gradient(to right,#0070c5 0,#004b83 100%);
}

/*========================================================
                        ヘッダー
=========================================================*/
header{
    top:0;
    left:0;
    z-index:999;
    width:100%;
    background:#fff;
    @include media-breakpoint-up(md){
        position:fixed;
    }
    .top_text{
        padding:5px 0;
        background:#f4f4f4;
        font-size:2vw;
        font-weight:bold;
        line-height:1;
        @include media-breakpoint-up(md){
            font-size:12px;
        }
    }
    .top_nav{
        padding:5px 0;
        .icon_nav{
            display:none;
            max-width:calc(100% - 240px);
            @include media-breakpoint-up(md){
                display:block;
            }
            li{
                margin-left:10px;
            }
        }
    }
    .bottom_nav{
        border-top:1px solid #eee;
        ul{
            position:relative;
            &:before{
                @include media-breakpoint-up(md){
                    content:"";
                    position:absolute;
                    left:0;
                    top:50%;
                    transform:translateY(-50%);
                    display:inline-block;
                    height:1rem;
                    width:1px;
                    background:#eee;
                }
            }
            li{
                position:relative;
                width:49%;
                @include media-breakpoint-up(md){
                    width:calc(100% / 6);
                    font-size:11px;
                }
                @include media-breakpoint-up(lg){
                    font-size:16px;
                }
                &:nth-child(2n + 1):before{
                    content:"";
                    position:absolute;
                    left:0;
                    top:50%;
                    transform:translateY(-50%);
                    display:inline-block;
                    height:1rem;
                    width:1px;
                    background:#eee;
                    @include media-breakpoint-up(md){
                        content:none;
                    }
                }
                &:after{
                    content:"";
                    position:absolute;
                    right:0;
                    top:50%;
                    transform:translateY(-50%);
                    display:inline-block;
                    height:1rem;
                    width:1px;
                    background:#eee;
                }
                &.active a:after{
                    transform:rotateY(0);
                }
                a{
                    position:relative;
                    display:block;
                    padding:10px 0;
                    font-weight:bold;
                    text-align:center;
                    @include media-breakpoint-up(md){
                        padding:20px 0;
                    }
                    &:before{
                        content:"";
                        position:absolute;
                        left:10%;
                        bottom:0;
                        display:inline-block;
                        height:1px;
                        width:80%;
                        background:#eee;
                        @include media-breakpoint-up(md){
                            content:none;
                        }
                    }
                    &:after{
                        content:"";
                        position:absolute;
                        left:0;
                        bottom:0;
                        display:inline-block;
                        height:4px;
                        width:100%;
                        background:map-get($color,'maincolor');
                        transition:transform .2s ease;
                        transform:rotateY(90deg);
                        transform-origin:left;
                    }
                }
            }
        }
    }
}


/*========================================================
                        フッター
=========================================================*/
footer{
    section{
        padding:80px 0 20px;
        .left{
            @include media-breakpoint-up(md){
                width:46.5%;
            }
            .logo_box{
                text-align:center;
                @include media-breakpoint-up(md){
                    text-align:left;
                }
                h2{
                    margin-bottom:10px;
                    @include media-breakpoint-up(md){
                        margin-bottom:0;
                    }
                }
            }
            address{
                margin:0;
                padding:15px 0;
                border-bottom:1px solid #ddd;
                color:#aaa;
                font-size:14px;
                font-weight:bold;
                line-height:1;
            }
            ul{
                li{
                    margin:15px 0;
                    dl{
                        display:flex;
                        dt{
                            width:10px;
                            height:10px;
                            margin-top:6px;
                            margin-right:20px;
                            background:map-get($color,'maincolor');
                        }
                        dd{
                            max-width:calc(100% - 30px);
                            font-size:3vw;
                            font-weight:bold;
                            line-height:1.6;
                            @include media-breakpoint-up(md){
                                font-size:14px;
                            }
                            span{
                                display:block;
                                font-size:2.5vw;
                                @include media-breakpoint-up(md){
                                    font-size:12px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .right{
            @include media-breakpoint-up(md){
                width:50%;
            }
        }
    }
    .copy{
        padding:20px 0;
        background:map-get($color,'maincolor');
        color:#fff;
        text-align:center;
        small{
            font-size:14px;
        }
    }
    #footer_btn{
        position:fixed;
        bottom:0;
        left:0;
        width:100%;
        @include media-breakpoint-up(md){
            display:none;
        }
        ul{
            display:flex;
            li{
                width:28%;
                &.tel{
                    a{
                        background:#fff;
                        color:map-get($color,'maincolor');
                    }
                }
                &.mail{
                    a{
                        background:linear-gradient(to bottom,#beb9a2 50%,#b6ad85 50%);
                    }
                }
                &.line{
                    a{
                        background:linear-gradient(to bottom,#33c733 50%,#00b900 50%);
                    }
                }
                &.to_top{
                    width:16%;
                    a{
                        background:linear-gradient(to bottom,#666 50%,#555 50%);
                    }
                }
                a{
                    display:block;
                    padding:5px 0;
                    color:#fff;
                    font-size:30px;
                    text-align:center;
                }
            }
        }
    }
}


/*========================================================
                        メイン
=========================================================*/

.container{
    max-width:1260px;
    margin:0 auto;
}

main{
    #mv{
        .mv_pc{
            display:none;
            @include media-breakpoint-up(md){
                display:inline-block;
            }
        }
        .mv_sp{
            @include media-breakpoint-up(md){
                display:none;
            }
        }
        .bottom{
            background:#dceaf7;
            @include media-breakpoint-up(md){
                padding:0 30px;
            }
        }
    }
}



