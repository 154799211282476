main{
    #area{
        padding:45px 0 80px;
        background:linear-gradient(-45deg,#ecf6ff 25%,#f5faff 25%,#f5faff 50%,#ecf6ff 50%,#ecf6ff 75%,#f5faff 75%) center center / 10px 10px;
        h2{
            margin-bottom:50px;
        }
        li{
            &:not(:last-child){
                margin-bottom:35px;
            }
            h3{
                padding:21px 0;
                font-size:24px;
                text-align:center;
            }
            p{
                padding:30px;
                background:#fff;
                font-weight:bold;
            }
        }
    }
}



