
main{
    .coop{
        position:relative;
        padding:40px 0 30px;
        background:linear-gradient(-45deg,map-get($color,'light_blue') 25%,map-get($color,'blue') 25%,map-get($color,'blue') 50%,map-get($color,'light_blue') 50%,map-get($color,'light_blue') 75%,map-get($color,'blue') 75%) center center / 10px 10px;
        &:before,&:after{
            content:"";
            position:absolute;
            display:block;
            width:100%;
            border-bottom:2px dotted #fff;
        }
        &:before{
            top:10px;
        }
        &:after{
            bottom:10px;
        }
        h2{
            position:relative;
            margin-bottom:30px;
            .challenge{
                position:absolute;
                right:0;
                top:0;
                max-width:15%;
                transform:translate(0,-80%);
                @include media-breakpoint-up(md){
                    max-width:10%;
                    transform:translate(0,-50%);
                }
                @include media-breakpoint-up(lg){
                    right:inherit;
                    left:50%;
                    top:50%;
                    transform:translate(350px,-53%);
                    max-width:inherit;
                }
                @include media-breakpoint-up(xl){
                    transform:translate(450px,-53%);
                }
            }
        }
        .bn_box{
            margin-bottom:20px;
            div{
                &.left{
                    margin-bottom:10px;
                    @include media-breakpoint-up(md){
                        max-width:51.6%;
                        margin-bottom:0;
                    }
                }
                &.right{
                    @include media-breakpoint-up(md){
                        max-width:46.8%;
                    }
                    ul{
                        flex-flow:column;
                        justify-content:space-between;
                        height:100%;
                        @include media-breakpoint-up(md){
                            display:flex;
                        }
                        li{
                            margin-bottom:10px;
                            @include media-breakpoint-up(md){
                                margin-bottom:0;
                            }
                        }
                    }
                }
            }
        }
    }
}



