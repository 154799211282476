
main{
    #question{
        padding:30px 0;
        @include media-breakpoint-up(md){
            padding:55px 0;
        }
        h2{
            margin-bottom:55px;
        }
        dl{
            margin-bottom:10px;
            dt{
                display:flex;
                justify-content:space-between;
                background:#fafafa;
                cursor:pointer;
                div{
                    width:calc(100% - 40px);
                    @include media-breakpoint-up(md){
                        width:calc(100% - 70px);
                    }
                }
                span{
                    vertical-align:middle;
                    font-weight:bold;
                    &.icon{
                        padding:0 10px;
                        font-size:6vw;
                        @include media-breakpoint-up(md){
                            padding:0 20px;
                            font-size:36px;
                        }
                    }
                    &.text{
                        font-size:3.5vw;
                        @include media-breakpoint-up(md){
                            font-size:18px;
                        }
                    }
                    &.plus{
                        position:relative;
                        background:map-get($color,'blue');
                        width:40px;
                        @include media-breakpoint-up(md){
                            width:70px;
                        }
                        &:before{
                            content:"";
                            position:absolute;
                            top:calc(50% - 1px);
                            left:calc(50% - 8px);
                            display:inline-block;
                            width:16px;
                            height:2px;
                            background:#fff;
                            @include media-breakpoint-up(md){
                                left:calc(50% - 12px);
                                width:24px;
                            }
                        }
                        &:after{
                            content:"";
                            position:absolute;
                            top:calc(50% - 1px);
                            left:calc(50% - 8px);
                            display:inline-block;
                            width:16px;
                            height:2px;
                            background:#fff;
                            transform:rotate(90deg);
                            transition:all .2s linear;
                            @include media-breakpoint-up(md){
                                left:calc(50% - 12px);
                                width:24px;
                            }
                        }
                        &.active:after{
                            transform:rotate(0);
                        }
                    }
                }
            }
            dd{
                display:none;
                padding:15px;
                font-size:3vw;
                @include media-breakpoint-up(md){
                    font-size:14px;
                }
                span.icon{
                    display:inline-block;
                    vertical-align:middle;
                    padding:0 20px 0 0;
                    font-size:6vw;
                    font-weight:bold;
                    color:#f39e26;
                    @include media-breakpoint-up(md){
                        font-size:36px;
                    }
                }
            }
        }
    }
}



