main{
    #reason{
        padding:30px 0;
        background:linear-gradient(-45deg,#ebf6ff 25%,#f5faff 25%,#f5faff 50%,#ebf6ff 50%,#ebf6ff 75%,#f5faff 75%) center center / 10px 10px;
        @include media-breakpoint-up(md){
            padding:55px 0;
        }
        ol{
            li{
                position:relative;
                width:49.5%;
                max-width:380px;
                margin-top:25px;
                padding:20px 5px;
                background:#fff;
                border:1px solid #ddd;
                border-radius:10px;
                font-weight:bold;
                line-height:1.4;
                text-align:center;
                @include media-breakpoint-up(md){
                    width:32.5%;
                    padding:40px 20px 30px;
                    margin-top:45px;
                }
                h3{
                    position:absolute;
                    left:50%;
                    top:0;
                    width:80%;
                    max-width:268px;
                    transform:translate(-50%,-57%);
                }
                .img{
                    margin:15px 0 10px;
                }
                p{
                    @include media-breakpoint-only(md){
                        font-size:14px;
                    }
                }
            }
        }
    }
}



