
main{
    #flow{
        padding:40px 0 30px;
        background:linear-gradient(-45deg,#ecf6ff 25%,#f5faff 25%,#f5faff 50%,#ecf6ff 50%,#ecf6ff 75%,#f5faff 75%) center center / 10px 10px;
        @include media-breakpoint-up(md){
            padding:70px 0 40px;
        }
        ol{
            margin-bottom:40px;
            @include media-breakpoint-only(md){
                width:420px;
                margin:0 auto 40px;
            }
            li{
                position:relative;
                &:not(:last-child){
                    margin-bottom:60px;
                }
                &:not(:last-child):after{
                    content:"";
                    position:absolute;
                    bottom:-50px;
                    left:50%;
                    transform:translateX(-50%);
                    display:inline-block;
                    width:90px;
                    height:40px;
                    background:url('../img/arrow_y_bottom.png') no-repeat center center;
                }
                .img{
                    position:relative;
                    @include media-breakpoint-up(lg){
                        width:35%;
                    }
                    span{
                        position:absolute;
                        left:0;
                        top:0;
                        padding:10px 40px 40px 10px;
                        background:linear-gradient(135deg,rgba(0,101,171,.8) 50%,transparent 50%);
                    }
                }
                .text{
                    padding:20px 35px;
                    background:#fff;
                    @include media-breakpoint-up(lg){
                        width:65%;
                        padding:55px 65px 0;
                    }
                    h3{
                        padding-bottom:10px;
                        margin-bottom:10px;
                        border-bottom:1px dashed #aaa;
                        font-size:5vw;
                        @include media-breakpoint-up(md){
                            padding-bottom:15px;
                            margin-bottom:15px;
                            font-size:26px;
                        }
                    }
                    p{
                        font-size:14px;
                    }
                }
            }
        }
        .credit{
            text-align:center;
            .card_inner{
                align-items:center;
                padding:10px;
                background:#fff;
                border:1px solid #ddd;
                border-radius:10px;
                font-size:4vw;
                font-weight:bold;
                text-align:left;
                @include media-breakpoint-up(md){
                    display:inline-flex;
                    font-size:18px;
                }
                i{
                    margin-right:10px;
                }
                img{
                    margin-left:25px;
                    @include media-breakpoint-up(md){
                        margin-left:50px;
                    }
                }
            }
        }
    }
}



