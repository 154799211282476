
main{
    #service{
        padding:55px 0;
        .container{
            h2{
                img{
                    max-width:60%;
                }
                .people{
                    bottom:-10px;
                    left:80%;
                }
            }
            &>p{
                margin:20px 0;
                font-size:3.5vw;
                font-weight:bold;
                line-height:1;
                @include media-breakpoint-up(md){
                    font-size:20px;
                }
            }
            ol{
                li{
                    border:4px solid #ddd;
                    border-radius:10px;
                    margin-bottom:10px;
                    @include media-breakpoint-up(md){
                        display:flex;
                    }
                    .img{
                        padding:10px 0;
                        background:#fafafa;
                        border-bottom:4px solid #ddd;
                        text-align:center;
                        @include media-breakpoint-up(md){
                            width:25%;
                            border-right:4px solid #ddd;
                            border-bottom:none;
                        }
                    }
                    dl{
                        display:flex;
                        flex-direction:column;
                        justify-content:center;
                        padding:20px;
                        @include media-breakpoint-up(md){
                            width:75%;
                            padding:0 40px;
                        }
                        dt{
                            margin-bottom:10px;
                            font-size:4.5vw;
                            @include media-breakpoint-up(md){
                                margin-bottom:5px;
                                font-size:28px;
                            }
                            .num{
                                display:inline-block;
                                width:35px;
                                height:35px;
                                line-height:30px;
                                margin-right:20px;
                                background:map-get($color,'blue');
                                border-radius:50%;
                                color:#fff;
                                font-weight:normal;
                                font-family:'oswald',sans-serif;
                                text-align:center;
                                @include media-breakpoint-up(md){
                                    width:50px;
                                    height:50px;
                                    line-height:42px;
                                }
                                img{
                                    width:55%;
                                }
                            }
                        }
                        dd{
                            line-height:1.4;
                        }
                    }
                }
            }
        }
    }
}



